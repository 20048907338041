@use "./config" as *;

.waf-fixtures {
  padding: 0 var(--full-space) calc(2 * var(--full-space)) var(--full-space);
  margin: 0 var(--half-space-negative);
  .title,
  .btn-text,
  .text,
  .number,
  .label,
  .team-name {
    color: var(--kabaddi-secondary-color);
  }
  .live-tab-container .btn-more,
  .fixtures-listing .btn-more {
    width: 100%;
    height: 100%;
    @include position(0, null, null, 0);
    .btn-text {
      border: none;
      font-size: 0;
    }
  }
  .list-body-wrapper {
    position: relative;
  }
  .filter-section,
  .tab-section {
    margin: var(--full-space) auto;
  }

  .filter-section {
    .select-list {
      .list-item {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }

  // .tab-section {
  //   position: relative;
  //   padding: 0 var(--half-space);
  //   width: 100%;
  //   margin: auto;
  //   transition: 500ms ease-in-out;
  //   transition-property: bottom;
  // }

  // .primary-tab {
  //   background-color: var(--white-color);
  //   border-bottom: 0.2rem solid var(--light-grey-color);
  //   width: 100%;
  //   list-style: none;
  //   height: 5rem;
  //   border-radius: 0.3rem;
  //   padding: 0.2rem;
  //   @include flex-config(flex, null, null, center);
  // }

  // .tab-name {
  //   position: relative;
  //   width: 50%;
  //   height: 100%;
  //   text-align: center;
  //   position: relative;
  //   cursor: pointer;
  //   @include flex-config(flex, null, center, center);
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     right: 0;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     height: 2rem;
  //     width: 0.1rem;
  //     background-color: var(--light-grey-color);
  //   }
  //   &:last-child {
  //     &::after {
  //       display: none;
  //     }
  //   }
  //   &.active,
  //   &:hover,
  //   &:focus {
  //     &::before {
  //       content: "";
  //       width: 100%;
  //       height: 0.4rem;
  //       background: var(--kabaddi-accent-color);
  //       position: absolute;
  //       bottom: -0.5rem;
  //       left: 0;
  //       right: 0;
  //       margin: 0 auto;
  //     }
  //     span {
  //       color: var(--kabaddi-accent-color);
  //     }
  //   }
  //   &.active {
  //     cursor: default;
  //   }
  //   span {
  //     color: var(--kabaddi-secondary-color);
  //     font-size: 1.4rem;
  //     font-family: $font-bold;
  //   }
  // }

  .tab-name {
    .tab-anchor {
      background: transparent;
    }
    &:hover,
    &.active {
      &::before {
        height: 0.4rem;
      }
      .btn-text {
        font-family: $font-bold;
        color: var(--kabaddi-accent-color);
      }
    }
  }

  .list-head {
    .title {
      font-family: $font-bold;
      font-size: 2rem;
    }
  }

  .match-status {
    display: none;
  }
  .graph-box {
    display: none;
  }
  .match-time-wrap {
    @include flex-config(flex, null, null, null);
  }

  .time-text {
    font: 1.4rem/1 $font-bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: var(--kabaddi-secondary-color);
    width: 2rem;
    @include flex-config(flex, null, center, center);
  }

  .score {
    width: 4rem;
    font: 1.8rem/1.1 $font-bold;
  }
  .item-wrapper {
    @include flex-config(flex, null, space-between, center);
  }
  .item-left {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0rem;
      border-right: var(--full-space) solid var(--light-grey-color);
      border-top: var(--full-space) solid transparent;
    }
  }
  .item-middle {
    .match-time {
      @include flex-config(flex, null, center, center);
      .graph-box {
        .circle-inner {
          fill: none;
          stroke-width: 2;
        }
      }
    }
  }
  .item-right {
    background: var(--grey-color);
    padding: var(--full-space);
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      border-right: var(--full-space) solid var(--white-color);
      border-top: var(--full-space) solid transparent;
    }
    .result-wrap {
      width: 60%;
      flex-basis: 60%;
    }
    .text {
      color: var(--kabaddi-secondary-color);
      font: 1rem/1 $font-bold;
    }
  }
  .team {
    width: calc((100% - 2rem) / 2);
    @include flex-config(flex, null, space-between, null);

    &-info {
      @include flex-config(flex, null, null, center);
    }

    &-image {
      width: 4rem;
      height: 4rem;
      background-color: var(--white-color);
      padding: 0.5rem;
      @include border-radius(50%);
    }
    &-score {
      @include flex-config(flex, null, null, center);
    }
    &-name {
      @include flex-config(flex, column, center, center);
      span {
        font-size: 1.2rem;
        white-space: nowrap;
      }
    }
    &-category {
      display: none;
    }

    &-a {
      .team-name {
        margin-left: var(--half-space);
      }
      .team-score {
        @include border-radius(3rem 0 0 3rem);
        padding-right: var(--half-space);
      }
      .score {
        text-align: right;
      }
    }
    &-b {
      @include flex-config(null, row-reverse, null, null);
      .team-info {
        @include flex-config(null, row-reverse, null, null);
      }
      .team-name {
        margin-right: var(--half-space);
      }
      .team-score {
        @include border-radius(0 3rem 3rem 0);
        padding-left: var(--half-space);
      }
    }
    &.match-update {
      max-width: 4rem;
    }
  }
  .accordion-head {
    display: none;
    height: 2.7rem;
    width: 12.45rem;
    padding: 0 calc(2 * var(--full-space));
    clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
  }

  .live-tab-container {
    .item-left {
      &:after {
        border-right-color: var(--kabaddi-secondary-color);
      }
      .title {
        background-color: var(--kabaddi-primary-color);
      }
    }
    .item-middle {
      background-color: var(--kabaddi-secondary-color);
    }
    .team-name {
      .text {
        color: var(--white-color);
      }
    }
    .btn-text,
    .number,
    .label,
    .team-name {
      color: var(--white-color);
    }
    .score {
      color: rgba(var(--white-color-rgb), 0.8);
    }
    .team {
      &.active {
        .score {
          color: var(--kabaddi-primary-color);
        }
      }
    }
    .time-text {
      color: rgba(var(--white-color-rgb), 0.8);
    }
    .circle-inner {
      fill: transparent;
    }
    .circle-outer {
      stroke: var(--kabaddi-primary-color);
      stroke-width: 4;
    }
  }

  .upcoming-tab-container {
    .item-left {
      .title {
        background-color: var(--dark-grey-color);
      }
    }
    .item-middle {
      background-color: var(--light-grey-color);
    }
    .time-text {
      background: none;
      color: var(--kabaddi-secondary-color);
      font-size: 1.4rem;
      font-family: $font-bold;
      padding-right: 2.5rem;
    }
    .time-type {
      color: var(--kabaddi-secondary-color);
      white-space: nowrap;
      font: 1.4rem/1 $font-bold;
    }
  }

  .recent-tab-container {
    .item-left {
      .title {
        background-color: var(--kabaddi-accent-color);
        color: var(--white-color);
      }
    }
    .item-middle {
      background-color: var(--light-grey-color);
    }
    .graph-box {
      .circle-outer {
        stroke: var(--recent-color);
      }
    }
    .score {
      color: rgba(var(--kabaddi-secondary-color-rgb), 0.8);
    }
    .team {
      &.active {
        .score {
          color: var(--kabaddi-accent-color);
        }
      }
    }
  }

  .list-head .title {
    color: var(--kabaddi-secondary-color);
  }
  .fixtures-listing {
    padding-bottom: calc(var(--full-space) * 2);
    margin-bottom: var(--full-space);
    border-bottom: 0.1rem solid rgba(var(--dark-grey-color-rgb), 0.5);
  }
  .btn-ticket {
    // z-index: var(--z-index1);
    .btn-text {
      background-color: var(--kabaddi-accent-color);
      padding: 0.8rem 2.5rem;
      color: var(--white-color);
      clip-path: polygon(100% 0, 100% 70%, 93% 100%, 0 100%, 0 0);
    }
  }
  .list-item {
    background: var(--grey-color);
    &:not(:last-child) {
      margin-bottom: var(--full-space);
    }
    .item-left {
      .title {
        height: 2.6rem;
        padding: var(--full-space);
        font-size: 1.2rem;
        margin: 0;
        @include flex-config(flex, null, null, center);
      }
    }
  }
  .item-middle {
    padding: var(--full-space);
  }
  .item .item-wrapper .team {
    background: transparent;
  }
  .item-wrapper {
    .team {
      background-color: var(--light-gray-color);
      .team-name {
        font-family: $font-bold;
        text-transform: uppercase;
      }
    }
  }

  .live-tab-container,
  .recent-tab-container {
    .match-time {
      position: relative;
      height: 100%;
      .time-text {
        position: absolute;
        left: 0rem;
        width: 100%;
        height: 100%;
      }
      .graph-box {
        display: block;
      }
    }
    .match-update {
      max-width: fit-content;
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .waf-fixtures {
    padding-top: 0;
    .layout-wrapper {
      max-width: var(--container-max-width);
      margin: 0 auto;
    }
    .tab-section {
      width: 55%;
    }
    .fixtures-listing {
      margin: calc(var(--full-space) * 2) auto;
      border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.2);
      .list-head {
        margin-bottom: 2rem;
      }
    }
    .item-wrapper {
      position: relative;
    }
    .list-item {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
    .list-body-wrapper {
      height: 11.5rem;
      @include flex-config(flex, null, space-between, center);

      .item-left {
        .title {
          @include flex-config(null, null, center, null);
        }
      }
      .item-left,
      .item-right {
        width: 20%;
        flex-basis: 20%;
        flex-shrink: 0;
        margin: 0;
        height: 100%;

        .item-wrapper {
          position: static;
          height: 100%;
          @include flex-config(null, column, center, flex-start);
        }
        .btn-ticket {
          margin-top: var(--full-space);
        }
        .title {
          height: 100%;
          margin: 0;
          font-size: 1.6rem;
          padding-left: var(--full-space);
        }
        .result-wrap .text {
          font-size: 1.4rem;
          line-height: 1.4;
          width: 90%;
        }
        .btn-more {
          .btn-text {
            border: none;
            font-size: 0;
          }
          &::after {
            content: "\e828";
            font-size: 2rem;
            color: var(--kabaddi-accent-color);
            font-family: $font-icon;
          }
        }
      }
      .item-right {
        .result-wrap {
          width: 100%;
          flex-basis: 100%;
        }
      }
      .item-middle {
        width: 60%;
        flex-basis: 60%;
        overflow: unset;
        height: 100%;
        @include flex-config(flex, column, center, null);
      }
    }
    .upcoming-tab-container,
    .recent-tab-container {
      .item-right {
        .btn-more {
          &::after {
            color: var(--kabaddi-accent-color);
          }
        }
      }
    }
    .live-tab-container,
    .upcoming-tab-container,
    .recent-tab-container {
      .item-right {
        .btn-more {
          &::after {
            right: var(--full-space);
            @include center(vertical);
          }
        }
      }
    }
    .match-time {
      .graph-box {
        width: 4.2rem;
        height: 4.2rem;
      }
    }
  }
}

@media screen and (min-width: $desktop-min-width) {
  .waf-fixtures .list-body-wrapper .team span:last-child {
    display: unset;
  }
  .waf-fixtures .list-body-wrapper {
    .team {
      &-image {
        width: 6rem;
        height: 6rem;
        padding: 1rem;
      }

      &-name {
        margin: 0 2rem;
        .text {
          font-size: 1.4rem;
        }
      }
      .score {
        width: 100%;
        font-size: 4.2rem;
      }
      &-a {
        .team-name {
          @include flex-config(null, null, null, flex-start);
        }
      }
      &-b {
        .team-name {
          @include flex-config(null, null, null, flex-end);
        }
      }
    }

    .item-left,
    .item-right {
      width: 22%;
      flex-basis: 22%;
    }
    .item-middle {
      width: 64%;
      flex-basis: 64%;
      overflow: unset;
    }
  }
}
